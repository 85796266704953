import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../constants/global-hooks";
import { RootState } from "../../store";
import { TextEnum } from "../../constants/text";

import "./payment.scss";

export const PaymentPage = () => {
  const { errorMessage } = useAppSelector((state: RootState) => state.payment);
  const animationRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (animationRef.current) {
      animationRef.current.classList.add("animate");
    }
  }, []);
  //ez egy error
  useEffect(() => {
    if (window.location.href.includes("&success=false&code=12006")) {
      return;
    }
    if (window.location.href.includes("PayerID=null")) {
      setLoading(false);
      const redirect = () => {
        window.location.href = `${window.location.href}&success=false&code=12006`;
      };
      const redirectTimeout = setTimeout(redirect, 1000);
      return () => clearTimeout(redirectTimeout);
    }
    if (
      errorMessage.message === "" &&
      !window.location.href.includes("&success=true")
    ) {
      const redirect = () => {
        const newURL = `${window.location.href}&success=true`;
        window.location.replace(newURL);
      };
      setLoading(false);
      const redirectTimeout = setTimeout(redirect, 1000);
      return () => clearTimeout(redirectTimeout);
    } else if (
      !window.location.href.includes("&success=true") &&
      !window.location.href.includes(`&code=${errorMessage.code}`)
    ) {
      setLoading(false);
      const redirect = () => {
        window.location.href = `${window.location.href}&success=false&code=${errorMessage.code}`;
      };
      const redirectTimeout = setTimeout(redirect, 1000);
      return () => clearTimeout(redirectTimeout);
    }
  
    const mobileRedirect = () => {      
      window.location.href = `${process.env.IOS_APP_ORIGIN}`;
    };
    const mobileRedirectTimeout = setTimeout(mobileRedirect, 3000); 
  
    return () => clearTimeout(mobileRedirectTimeout);
  }, [errorMessage]);
  
  return (
    <div className="payment-page">
      {loading && (
        <>
          <div ref={animationRef} className="animation">
            <div
              className={`circle ${
                !window.location.href.includes("&success=true")
                  ? "failed"
                  : "success"
              }`}
            />
            <div className="cross">
              <div
                className={`line1 ${
                  window.location.href.includes("&success=true") && "success"
                }`}
              />
              <div
                className={`line2 ${
                  window.location.href.includes("&success=true") && "success"
                }`}
              />
            </div>
          </div>
          <h1>
            {!window.location.href.includes("&success=true")
              ? errorMessage.message
              : TextEnum.PAYMENT_WAS_SUCCESSFUL}
          </h1>
        </>
      )}
    </div>
  );
};
